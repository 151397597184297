<template>
  <div class="sidebar-section">
    <div class="sidebar-dropdown-handle isOpen">
      <span class="title">Area Items</span>
      <!-- <span class="icon">
          <i class="material-icons-outlined align-middle button"> expand_less </i>
        </span> -->
    </div>

    <div class="content-section">
      <div class="playlist-items-container">
        <draggable
          v-model="playlistItems"
          v-if="!playlistItemsLoading && playlistItems.length > 0"
          @update="handleItemsReordered"
        >
          <div
            v-for="(playlistItem, index) in playlistItems"
            :key="index"
            class="playlist-item-container"
            :class="{
              selected:
                selectedPlaylistItem && playlistItem.item_id === selectedPlaylistItem.item_id,
            }"
            @click="selectItem(playlistItem)"
          >
            <div
              class="playlist"
              :title="`${getPlaylistItemDisplayName(playlistItem)} (${playlistItem.item_type})`"
            >
              <PlaylistItemImage
                :playlistItem="playlistItem"
                @openItemModal="setPlaylistPreviewItem"
              />

              <div class="playlist-body">
                <div
                  v-if="playlistItem.display_priority === DISPLAY_PRIORITY.PRIORITY"
                  class="priority-indicator"
                >
                  PRIO
                </div>
                <div class="playlist-name">
                  <PlaylistItemIcon :itemType="playlistItem.item_type" />

                  <span> {{ getPlaylistItemDisplayName(playlistItem) }} </span>
                </div>

                <div class="details">
                  <PlaylistItemStatus
                    :itemStatus="itemScheduleStatus(playlistItem)"
                    :hasActivePriorityItem="hasActivePriorityItem"
                    :playlistItem="playlistItem"
                  />

                  <div class="time" @click="openTimeDurationModal(playlistItem)">
                    <i class="material-icons-outlined align-middle">timer</i>
                    {{ playlistTotalTime(playlistItem.display_timer) }}
                  </div>

                  <div class="dropdown">
                    <i
                      class="material-icons align-middle more-buttons dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      more_horiz
                    </i>
                    <div class="role-options dropdown-menu dropdown-menu-right">
                      <!-- <button
                        v-if="playlistItem.item_type.includes('local-template')"
                        type="button"
                        class="dropdown-item"
                        @click="editTileOnTemplateEditor(playlistItem.template)"
                      >
                        <i class="material-icons-outlined align-middle">format_shapes</i>
                        Edit Template
                      </button> -->

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openEditItemNameModal(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">edit_note</i>
                        Edit item name
                      </button>

                      <!-- <button
                        v-if="playlistItem.item_type.includes('template')"
                        type="button"
                        class="dropdown-item"
                        @click="duplicateLocalTemplate(playlistItem.template)"
                      >
                        <i class="material-icons-outlined align-middle">content_copy</i>
                        Duplicate item
                      </button> -->

                      <button
                        v-if="playlistItem.item_type === CONTENT_TYPE_TEMPLATE_APP"
                        type="button"
                        class="dropdown-item"
                        @click="convertToLocalTemplate(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">autorenew</i>
                        Convert to Template V2
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openSchedulingModal(playlistItem)"
                      >
                        <i class="material-icons-outlined align-middle">today</i>
                        Schedule
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="openPriorityModal(playlistItem)"
                      >
                        <div class="priority-icon"></div>
                        Set Priority
                      </button>

                      <button
                        v-if="selectedPlaylistItemType === 'audio'"
                        type="button"
                        class="dropdown-item"
                        @click="openAudioModal(playlistItem)"
                      >
                        <i class="material-symbols-outlined">volume_up</i>
                        Audio
                      </button>

                      <button
                        type="button"
                        class="dropdown-item"
                        @click="deletePlaylistItem(playlistItem)"
                      >
                        <i class="material-icons align-middle">delete</i>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <div class="my-3" v-else-if="playlistItemsLoading">
          <Loader />
        </div>

        <div v-else class="no-data">No Items</div>

        <BaseButton
          icon="add"
          class="w-100"
          variant="outline"
          @click.native="openPlaylistContentModal"
        >
          Add New
        </BaseButton>
      </div>
    </div>

    <PlaylistContentModal
      v-if="showPlaylistContentModal"
      @closeModal="showPlaylistContentModal = false"
      @openUploadModal="showFileUploadPopUp"
      @openContentBrowser="openContentBrowser"
      @openAppsModal="openAppsModal"
      @openTemplateModal="openTemplateModal"
      @openLinkModal="openLinkModal"
    />

    <ContentAddModal ref="addContentModal" @new-content-added="addPlaylistItems" />

    <ContentBrowserModal
      ref="content_browser_modal"
      allow-multiple-item-select
      @submitted="[addPlaylistItems($event), closeModal()]"
      :title="contentBrowserTitle"
      :filterItemTypes="contentBrowserTypes"
    />

    <PlaylistItemDurationModal
      v-if="showPlaylistDurationModal"
      @closeModal="showPlaylistDurationModal = false"
      :playlistItem="selectedPlaylistItem"
      @setSchedule="setPlaylistItemDuration"
    />

    <Scheduler
      v-if="showSchedulingModal"
      :playlistItem="selectedPlaylistItem"
      @closeModal="showSchedulingModal = false"
      @setSchedule="setPlaylistItemConfig"
    />

    <PlaylistItemDisplayPriority
      v-if="showItemPriorityModal"
      :playlistItem="selectedPlaylistItem"
      @closeModal="showItemPriorityModal = false"
      @setPriority="setPlaylistItemConfig"
    />

    <PlaylistItemAudioModal
      v-if="showItemAudioModal"
      :playlistItem="selectedPlaylistItem"
      @close="showItemAudioModal = false"
      @save="setPlaylistItemAudio"
    />

    <TemplateFolderBrowserModal
      ref="template_folder_model"
      isTemplateForTiles
      @createTile="addTileAsItem"
    />

    <PlaylistItemModal
      v-if="previewItem"
      :previewItem="previewItem"
      :playlist="playlist"
      @closeModal="previewItem = null"
    />

    <AddLinkModal
      v-if="showLinkModal"
      :playlist="playlist"
      @closeModal="showLinkModal = false"
      @addLayerToPlaylist="addLayerToPlaylist"
    />

    <EditSingleItem
      v-if="showEditItemNameModal"
      title="Edit Playlist Item Name"
      label="Name"
      placeholder="Edit Name"
      :field="playlistItemName"
      :validation="maxLength(60)"
      @field-modified="handleEditName"
      @closeModal="closeEditItemModal"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import draggable from 'vuedraggable';

  import PlaylistContentModal from '@/components/playlist/PlaylistContentModal.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import ContentAddModal from '@/components/content/ContentAddModal.vue';
  import PlaylistItemDurationModal from '@/components/screens/PlaylistItemDurationModal.vue';
  import Loader from '@/components/common/Loader.vue';
  import Scheduler from '@/components/common/scheduler/Scheduler.vue';
  import PlaylistItemDisplayPriority from '@/components/common/PlaylistItemDisplayPriority.vue';
  import PlaylistItemModal from '@/components/common/PlaylistItemModal.vue';
  import PlaylistItemImage from '@/components/screens/PlaylistItemImage.vue';
  import PlaylistItemStatus from '@/components/screens/PlaylistItemStatus.vue';
  import PlaylistItemIcon from '@/components/screens/PlaylistItemIcon.vue';
  import AddLinkModal from '@/components/playlist/AddLinkModal.vue';
  import TemplateFolderBrowserModal from '@/components/layoutDesigner/editor/TemplateFolderBrowserModal.vue';
  import EditSingleItem from '@/components/common/EditSingleItem.vue';
  import { getSimpleType, validationsMixin } from '@/helpers/mixins';

  import {
    DAYS_OF_THE_WEEK,
    PLAYLIST_SCHEDULE_STATUS,
    SCHEDULE_TYPES,
    DISPLAY_PRIORITY,
    CONTENT_TYPE_TEMPLATE,
  } from '@/constant/const';
  import { transformSecondsToTimer } from '@/helpers/utils';
  import { simpleTypeMixin } from '@/helpers';
  import { generateWidgetId, WIDGET_TYPES } from '@/models/layoutDesigner';

  import { apiCloneLocalTemplateOnPlaylists, apiConvertTemplateToTile } from '@/api/tiles';
  import { CONTENT_TYPE_TEMPLATE_APP } from '@/constant/const';

  import {
    SET_SAVE_LAYOUT,
    REMOVE_PLAYLIST_ITEM,
    SET_PLAYLIST_UPDATED,
    UPDATE_PLAYLIST_ITEM,
  } from '@/store/actions/layoutDesigner';
  import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';
  import PlaylistItemAudioModal from '@/components/common/PlaylistItemAudioModal.vue';
  import { GET_PLAYLIST_ITEMS } from '@/store/actions/playlist';

  export default {
    name: 'LayoutPlaylistsItems',

    mixins: [simpleTypeMixin, validationsMixin],

    components: {
      PlaylistItemAudioModal,
      PlaylistContentModal,
      ContentBrowserModal,
      ContentAddModal,
      draggable,
      Loader,
      PlaylistItemDurationModal,
      Scheduler,
      PlaylistItemModal,
      PlaylistItemImage,
      PlaylistItemIcon,
      AddLinkModal,
      TemplateFolderBrowserModal,
      PlaylistItemDisplayPriority,
      EditSingleItem,
      PlaylistItemStatus,
    },

    props: {
      playlist: {
        type: Object,
        default: () => ({}),
      },

      designerMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        playlistItems: [],
        showPlaylistDurationModal: false,
        showDeleteScreenModal: false,
        showSchedulingModal: false,
        showItemPriorityModal: false,
        showItemAudioModal: false,
        showPlaylistContentModal: false,
        showLinkModal: false,
        previewItem: null,
        selectedPlaylistItem: null,
        playlistItemsLoading: false,
        contentBrowserTitle: '',
        contentBrowserTypes: [],
        PLAYLIST_SCHEDULE_STATUS,
        DISPLAY_PRIORITY,
        showEditItemNameModal: false,
        CONTENT_TYPE_TEMPLATE_APP,
      };
    },

    mounted() {
      this.setPlaylistItems();
    },

    computed: {
      organisation() {
        return this.$store.state.organisation.organisation || this.$store.state.auth.organisation;
      },

      layoutId() {
        return this.$route.params.layout_id;
      },

      playlistItemName() {
        if (this.selectedPlaylistItem) {
          return (
            this.selectedPlaylistItem.local_name ||
            this.selectedPlaylistItem.template?.name ||
            this.selectedPlaylistItem.name ||
            this.selectedPlaylistItem.item_name
          );
        }
      },

      selectedPlaylistItemType() {
        if (!this.selectedPlaylistItem) return null;
        return getSimpleType(this.selectedPlaylistItem.item_type);
      },

      hasActivePriorityItem() {
        for (const playlistItem of this.playlistItems) {
          const status = this.itemScheduleStatus(playlistItem);
          if (
            status === PLAYLIST_SCHEDULE_STATUS.ACTIVE &&
            playlistItem.display_priority === DISPLAY_PRIORITY.PRIORITY
          ) {
            return true;
          }
        }
        return false;
      },

      playlistWid() {
        return this.playlist.object?.wid || this.playlist.wid;
      },
    },

    methods: {
      setPlaylistItems() {
        this.playlistItems = this.$store.getters.getPlaylistItems(this.playlistWid);
      },

      openEditItemNameModal(item) {
        this.selectedPlaylistItem = item;
        this.showEditItemNameModal = true;
      },

      closeEditItemModal() {
        this.showEditItemNameModal = false;
      },

      async handleEditName(newItemName) {
        await this.setPlaylistItemConfig({
          wid: this.selectedPlaylistItem.wid,
          local_name: newItemName,
        });

        this.closeEditItemModal();
      },

      getPlaylistItemDisplayName({ name, item_name, template, local_name }) {
        return local_name || template?.name || name || item_name;
      },

      playlistTotalTime(seconds = 0) {
        return transformSecondsToTimer(seconds).substring(3);
      },

      itemScheduleStatus(playlistItem) {
        if (!playlistItem.scheduler || playlistItem.scheduler.available === SCHEDULE_TYPES.ALWAYS) {
          return PLAYLIST_SCHEDULE_STATUS.ACTIVE;
        }

        const { type, timeToTime, daysTime, daysTimes, daysHourType, days } =
          playlistItem.scheduler;

        const fromDate = timeToTime.from ? moment(timeToTime.from).toDate() : null;
        const toDate = timeToTime.to ? moment(timeToTime.to).toDate() : null;

        const isFromDateValid = fromDate ? moment(fromDate).isBefore() : true;
        const isToDateValid = toDate ? moment(toDate).isAfter() : true;

        if (type === SCHEDULE_TYPES.TIME_TO_TIME && isFromDateValid) {
          return isToDateValid
            ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
            : PLAYLIST_SCHEDULE_STATUS.FINISHED;
        } else if (type === SCHEDULE_TYPES.TIME_TO_TIME) {
          return PLAYLIST_SCHEDULE_STATUS.SCHEDULED;
        } else if (type === SCHEDULE_TYPES.ADVANCED && !isFromDateValid) {
          return PLAYLIST_SCHEDULE_STATUS.SCHEDULED;
        } else if (type === SCHEDULE_TYPES.ADVANCED && !isToDateValid) {
          return PLAYLIST_SCHEDULE_STATUS.FINISHED;
        }

        const now = moment();
        const currentDay = DAYS_OF_THE_WEEK[now.isoWeekday() - 1];
        const midnight = now.clone().startOf('day');
        const currentMinute = now.diff(midnight, 'minutes');

        const isValidDay =
          (daysHourType === SCHEDULE_TYPES.SAME_HOURS && days.length === 0) ||
          days.includes(currentDay);

        if (!isValidDay) {
          return PLAYLIST_SCHEDULE_STATUS.INACTIVE;
        }

        if (daysHourType === SCHEDULE_TYPES.SAME_HOURS) {
          return currentMinute >= daysTime[0] && currentMinute <= daysTime[1]
            ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
            : PLAYLIST_SCHEDULE_STATUS.INACTIVE;
        }

        const currentRangeOfMinutes = daysTimes[currentDay];

        return currentMinute >= currentRangeOfMinutes[0] &&
          currentMinute <= currentRangeOfMinutes[1]
          ? PLAYLIST_SCHEDULE_STATUS.ACTIVE
          : PLAYLIST_SCHEDULE_STATUS.INACTIVE;
      },

      async deletePlaylistItem(playlistItem) {
        const deletedItemPriority = playlistItem.item_priority;
        this.deleteItem(playlistItem);
        this.setPlaylistItems(); //Reorder items to update item priority

        this.playlistItems.forEach((item) => {
          if (item.item_priority < deletedItemPriority) return;

          this.updatePlaylistItem({ ...item, item_priority: item.item_priority - 1 });
        });

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      openPlaylistContentModal() {
        this.showPlaylistContentModal = true;
      },

      // Add Item to playlist
      async addPlaylistItems(objects) {
        try {
          if (!objects?.length) return;

          objects.forEach((item, index) => {
            const playlistItem = {
              ...item,
              item_id: item.item_id || item.playlistitem,
              item_priority: this.playlistItems.length + index + 1,
              display_priority: DISPLAY_PRIORITY.STANDARD,
              scheduler: null,
              wid: generateWidgetId(),
            };

            // init default value for audio widget
            if (getSimpleType(playlistItem.item_type) === WIDGET_TYPES.AUDIO) {
              playlistItem.mute = false;
              playlistItem.volume = 1;
            }

            // RSS App
            if (item.display || item.theme) {
              playlistItem.display = { ...item.display };
              playlistItem.theme = item.theme;
            }

            // Trafiklab App
            if (item.viewerStyles) {
              playlistItem.timetable = [...item.timetable];
              playlistItem.viewerStyles = {
                ...item.viewerStyles,
                background: { ...item.viewerStyles.background },
                departures: { ...item.viewerStyles.departures },
                line: { ...item.viewerStyles.line },
                title: { ...item.viewerStyles.title },
              };
            }

            // Table App
            if (item.rows || playlistItem.styles) {
              playlistItem.rows = item.rows;
              playlistItem.styles = {
                body: { ...item.styles.body },
                heading: { ...item.styles.heading },
                pagination: { ...item.styles.pagination },
                table: { ...item.styles.table },
              };
            }

            // Weather App
            if (item.weather) {
              playlistItem.weather = { ...item.weather };
            }

            this.updatePlaylistItem(playlistItem);
          });

          this.setPlaylistItems();
          this.updateLayoutDesigner();

          // close modal
          this.showPlaylistContentModal = false;
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
      },

      updatePlaylistItem(playlistItem) {
        this.$store.commit(UPDATE_PLAYLIST_ITEM, {
          playlistItem,
          playlistWid: this.playlistWid,
        });
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },

      deleteItem(playlistItem) {
        const deleteArgs = {
          playlistWid: this.playlistWid,
          playlistItem,
        };

        this.$store.commit(REMOVE_PLAYLIST_ITEM, deleteArgs);
      },

      showFileUploadPopUp() {
        this.$refs.addContentModal.open();
      },

      openContentBrowser() {
        this.contentBrowserTitle = '';
        this.contentBrowserTypes = ['image', 'video', 'audio', 'pdf'];
        this.$refs.content_browser_modal.open();
      },
      openAppsModal() {
        this.contentBrowserTitle = 'Browse Apps';
        this.contentBrowserTypes = ['app'];
        this.$refs.content_browser_modal.open();
      },

      getEncodedURL(baseUrl, uri = null) {
        return `${baseUrl}/${encodeURI(uri)}`;
      },

      selectItem(selectedItem) {
        this.selectedPlaylistItem = selectedItem;
      },

      openTimeDurationModal(item) {
        this.selectedPlaylistItem = item;
        this.showPlaylistDurationModal = true;
      },

      openSchedulingModal(item) {
        this.selectedPlaylistItem = item;
        this.showSchedulingModal = true;
      },

      openPriorityModal(item) {
        this.selectedPlaylistItem = item;
        this.showItemPriorityModal = true;
      },

      openAudioModal(item) {
        this.selectedPlaylistItem = item;
        this.showItemAudioModal = true;
      },

      editTileOnTemplateEditor(tile) {
        this.$router.push({
          name: 'Template',
          query: {
            tile: tile.template_id,
            playlist: this.playlist.playlist_id,
            layout: this.layoutId,
            name: '',
          },
          params: {
            templateId: tile.template_id,
          },
        });
      },

      async duplicateTemplate(template) {
        try {
          await this.addTileAsItem(template);
        } catch (error) {
          this.$toasted.global.general_error({
            message: 'Template duplication failed',
          });
        }
      },

      async duplicateLocalTemplate(template) {
        try {
          await apiCloneLocalTemplateOnPlaylists(
            template.template_id,
            this.playlist?.playlist_id,
            [],
            this.playlistItems.length + 1,
            screenId,
            groupId,
          );

          this.updateLayoutDesigner();
        } catch (error) {
          console.log('duplicateLocalTemplate ~ error:', error);

          this.$toasted.global.general_error({
            message: 'Template duplication failed',
          });
        }
      },

      async setPlaylistItemDuration({ wid, duration }) {
        const playlistItem = this.playlistItems.find((item) => item.wid === wid);
        playlistItem.display_timer = Number(duration);

        this.updatePlaylistItem(playlistItem);

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      async setPlaylistItemAudio({ wid, mute, volume }) {
        const playlistItem = this.playlistItems.find((item) => item.wid === wid);
        playlistItem.mute = mute;
        playlistItem.volume = volume;

        this.updatePlaylistItem(playlistItem);

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      async setPlaylistItemConfig({ wid, scheduler, priority, local_name }) {
        const playlistItem = this.playlistItems.find((item) => item.wid === wid);

        if (scheduler) {
          playlistItem.scheduler = scheduler;
        }
        if (priority) {
          playlistItem.display_priority = priority;
        }
        if (local_name) {
          playlistItem.local_name = local_name;
        }

        this.updatePlaylistItem(playlistItem);

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      async handleItemsReordered() {
        this.playlistItems.forEach((item, index) => {
          this.updatePlaylistItem({ ...item, item_priority: index });
        });

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      async addTileAsItem(template) {
        const playlistItem = {
          content_type: CONTENT_TYPE_TEMPLATE.template,
          display_timer: 15,
          display_priority: DISPLAY_PRIORITY.STANDARD,
          item_id: template.settings.appId,
          item_type: 'app/template',
          item_priority: this.playlistItems.length + 1,
          item_name: template.name,
          name: template.name,
          scheduler: null,
          template,
          thumbnail_url: template.img,
          wid: generateWidgetId(),
        };

        this.$store.commit(UPDATE_PLAYLIST_ITEM, {
          playlistItem,
          playlistWid: this.playlistWid,
        });

        this.setPlaylistItems();
        this.updateLayoutDesigner();
      },

      updateLayoutDesigner() {
        this.$store.commit(SET_SAVE_LAYOUT);
        this.$store.commit(SET_PLAYLIST_UPDATE_STATE, true);
        this.$store.dispatch(SET_PLAYLIST_UPDATED, this.playlist.assocId);
      },

      openTemplateModal() {
        this.$refs.template_folder_model.open();
      },

      openLinkModal() {
        this.showLinkModal = true;
      },

      setPlaylistPreviewItem(playlistItem) {
        this.previewItem = playlistItem;
      },

      addLayerToPlaylist(playlistItem) {
        this.addPlaylistItems([playlistItem]);
      },

      async convertToLocalTemplate(playlistItem) {
        try {
          await apiConvertTemplateToTile(
            playlistItem.template?.template_id,
            this.layoutId,
            this.playlist.playlist_id,
          );
          this.$toasted.global.general_success({
            message: 'Template converted successfully',
          });
          const requestArgs = {
            playlist_id: this.playlist.playlist_id,
            params: {},
            wid: this.playlistWid,
            saveItems: true,
          };

          await this.$store.dispatch(GET_PLAYLIST_ITEMS, requestArgs);
          this.setPlaylistItems();
        } catch (err) {
          this.$toasted.global.general_error({
            message: 'An error occured while converting the template to v2 template',
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .playlist-items-container .playlist-item-container {
    &.selected:hover .image .move {
      display: none;
    }

    .playlist:hover {
      .move {
        display: flex;
      }

      .image:hover {
        .search {
          display: flex;
        }

        .move {
          display: none;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .sidebar-section {
    padding-top: 16px;
    padding-bottom: 24px;

    & + .sidebar-section {
      border-top: 1px solid $lineGrey;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .playlist-items-container {
    display: flex;
    flex-direction: column;

    .playlist-item-container {
      display: flex;
      align-items: center;
      padding: 10px 0;

      & + .playlist-item-container {
        border-top: 1px solid $lineGrey;
      }

      &.selected {
        .playlist {
          background-color: $backgroundGrey2;
        }
      }
    }

    .playlist {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 100px 1fr;
      align-items: center;
      padding: 10px;
      gap: 8px;
      width: 100%;
      transition: background 0.25s ease-in;

      .playlist-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .priority-indicator {
          position: absolute;
          top: 0;
          right: 0;
          width: 40px;
          height: 21px;
          background-color: #e3003a;
          color: #ffffff;
          font-weight: 700;
          font-size: 10px;
          border-radius: 10.5px;
          text-align: center;
          padding-top: 3px;
        }
      }

      .playlist-name {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-weight: bold;

        span {
          display: -webkit-box;
          word-wrap: break-word;
          word-break: break-all;
          max-height: 32px;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        i {
          max-width: 24px;
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }

      .time {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .date {
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover {
          color: $primaryRed;
        }
      }

      &:hover {
        background-color: $backgroundGrey2;
        cursor: pointer;
      }
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin-bottom: 8px;

    i {
      color: $primaryText;
    }

    &.title {
      margin-bottom: 24px;
    }

    &.playlist-title {
      display: flex;
      justify-content: space-between;
    }
  }

  .dropdown-item {
    .priority-icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url('@/assets/img/priority.svg');
    }
    &:hover {
      .priority-icon {
        background-image: url('@/assets/img/priority-red.svg');
      }
    }
  }

  .sidebar-dropdown-handle {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &.isOpen {
      .icon {
        color: #e3003a;
      }
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #333332;
    }

    .icon {
      color: #333332;
    }
  }

  .no-data {
    margin-bottom: 20px;
    font-size: 16px;
    color: $secondaryText;
  }
</style>
