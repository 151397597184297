<template>
  <div class="main-container" v-if="isLoading">
    <Loader />
  </div>

  <div class="main-container" id="ScreenView" v-else>
    <div class="ui-content">
      <div class="ui-content-header">
        <div class="left">
          <div class="back-button" @click="goToScreens">
            <i class="material-icons-outlined align-middle">arrow_back</i>
          </div>

          <div class="breadcrumbs">
            <span>Screens / </span>
            <span v-for="(item, idx) in breadcrumbs" :key="idx">
              <b v-if="idx === breadcrumbs.length - 1">{{
                typeof item === 'string' ? item : ''
              }}</b>
              <span v-else class="breadcrumb-group" @click="() => goToParentGroup(item.id)">
                {{ item?.name }} /
              </span>
            </span>
          </div>
        </div>

        <div class="right">
          <RotateScreenButton
            title="Rotate Screen/s"
            :isRotating="isRotating"
            @rotateScreens="rotateScreen"
          />
          <button
            @click="toggleExpandPlayer"
            :title="expandPlayer ? 'Fit to page' : 'Expand'"
            class="icon"
          >
            <i class="material-icons-outlined align-middle">
              {{ expandPlayer ? 'fullscreen_exit' : 'fullscreen' }}
            </i>
          </button>
          <BaseButton
            icon="file_upload"
            :loading="isRefreshing"
            variant="outline"
            :disabled="isPublishButtonDisabled"
            :title="isPublishButtonDisabled ? 'No changes has been done' : 'Publish new changes'"
            @click="refreshScreen"
          >
            {{ isPublishButtonDisabled ? 'Published' : 'Publish' }}
          </BaseButton>
        </div>
      </div>

      <div ref="playerContainer" class="ui-content-body scrollbar">
        <div
          class="central-container"
          :class="{
            expanded: expandPlayer && layout?.settings.isHorizontal,
          }"
        >
          <div v-if="filteredScreens.length > 0" class="display" :style="playerStyle">
            <Player
              ref="player"
              :layout_id="layoutId"
              class="h-100"
              :key="layoutId"
              :screenId="filteredScreens[0].id"
              isPreview
              highlight
            />
          </div>
        </div>
      </div>
    </div>

    <ScreenSidebar :screens="filteredScreens" :isOverridable="isGroupOverridable" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Loader from '@/components/common/Loader.vue';
  import Player from '@/components/player/Index.vue';
  import ScreenSidebar from '@/components/screens/screen/ScreenSidebar.vue';
  import RotateScreenButton from '@/components/common/screens/RotateScreenButton.vue';
  import {
    GROUP_SCREEN_GROUP_SCREENS,
    SCREEN_GROUPS_FETCH,
    SCREENS_RESTART,
    SET_SCREEN_GROUP,
  } from '@/store/actions/screens';
  import { ALLOW_PLAYER_MODAL, SET_SCREEN_SIZE } from '@/store/actions/player';
  import { apiGetGroupOverridePermission, rotateScreen } from '@/api/screens';
  import _ from 'lodash';
  import { ContentStatus, PublicationType } from '@/types/api/screens';
  import { SCREEN_GROUP_CONTENT_CHANGED } from '@/store/actions/screenContentManagment';
  import { apiGetScreenGroupLayout } from '@/api/screenGroupLayout';
  import { SET_SELECTED_WIDGET } from '@/store/actions/player';

  export default {
    name: 'ScreenGroupView',

    provide() {
      return {
        handleContentChange: this.handleContentChange,
      };
    },

    components: {
      Loader,
      Player,
      ScreenSidebar,
      RotateScreenButton,
    },

    data() {
      return {
        isLoading: false,
        isSaving: false,
        isRotating: false,
        isRefreshing: false,
        isGroupOverridable: true,
        screens: [],
        playerContainerSize: {
          height: 0,
          width: 0,
        },
        expandPlayer: false,
        screenGroupLayout: null,
      };
    },

    async mounted() {
      this.$store.commit(ALLOW_PLAYER_MODAL, false);
      this.$store.commit(SET_SELECTED_WIDGET, null);

      await Promise.all([this.fetchScreens(), this.fetchScreenGroupLayout(), this.fetchGroups()]);

      this.playerContainerSize = {
        height: this.$refs.playerContainer.clientHeight,
        width: this.$refs.playerContainer.clientWidth,
      };

      await this.fetchGroupPermission();
    },

    watch: {
      async groupId() {
        this.$store.commit(SET_SELECTED_WIDGET, null);
        await Promise.all([this.fetchScreens(), this.fetchData(true)]);
      },
    },

    computed: {
      isPublishButtonDisabled() {
        return this.screenGroupLayout?.contentStatus === ContentStatus.Published;
      },

      ...mapState({
        groups: (state) => state.screens.screenGroups.data,
      }),

      layoutId() {
        return this.$route.params.layoutId;
      },

      filteredScreens() {
        return this.screens.filter((screen) => screen.layout === this.layoutId);
      },

      layout() {
        return this.$store.state.player.layout;
      },

      actionsDisabled() {
        return this.isRotating || this.isLoading || this.isSaving;
      },

      playlist() {
        // TODO: get layers info
        return this.$route.params.layerId
          ? this.layers.find((layer) => layer.playlist_id === this.$route.params.layerId)
          : null;
      },

      groupId() {
        return this.$route.params.groupId;
      },

      group() {
        if (!this.groups) return null;
        return this.groups.find((g) => g.id === +this.groupId);
      },

      breadcrumbs() {
        let path = [];
        let group = this.group;

        while (group) {
          path.unshift(group);
          group = this.groups.find((g) => g.id === group.parent_id);
        }

        if (!!this.layout && path.length > 0) {
          let lastItem = path.pop();
          path.push(`${lastItem.name} - ${this.layout.settings.name}`);
        }

        return path;
      },

      screenSize() {
        return this.$store.state.player.screenSize;
      },

      aspectRatio() {
        const { aspectRatio } = this.layout.settings;
        const aspectRatioValues = aspectRatio.split(':');
        const ratio = aspectRatioValues[0] / aspectRatioValues[1];

        return ratio;
      },

      playerWidthLimit() {
        const { isHorizontal } = this.layout.settings;
        const { width } = this.playerContainerSize;

        return isHorizontal || width < 800 ? width : 800;
      },

      playerHeightLimit() {
        const { isHorizontal } = this.layout.settings;
        const { height } = this.playerContainerSize;

        return isHorizontal || height < 800 ? height : 800;
      },

      playerSize() {
        if (!this.layout) return {};

        const { isHorizontal } = this.layout.settings;

        const { width, height } = this.playerContainerSize;

        let playerWidth = isHorizontal ? width : height / this.aspectRatio;
        let playerHeight = isHorizontal ? width / this.aspectRatio : height;

        if (this.expandPlayer) {
          playerWidth = isHorizontal
            ? this.playerWidthLimit * this.aspectRatio
            : this.playerWidthLimit;
          playerHeight = isHorizontal
            ? this.playerHeightLimit
            : this.playerHeightLimit * this.aspectRatio;
        }

        return {
          width: playerWidth,
          height: playerHeight,
        };
      },

      playerStyle() {
        const { width = this.screenSize.width, height = this.screenSize.height } = this.playerSize;

        return {
          width: `${width}px`,
          height: `${height}px`,
          position: 'relative',
        };
      },
    },

    methods: {
      async handleContentChange() {
        const screenIds = this.screens.map((screen) => screen.id);

        await this.$store.dispatch(SCREEN_GROUP_CONTENT_CHANGED, {
          screenGroupId: this.group.id,
          layoutId: this.layout.layout_id,
          screenIds: screenIds,
        });
        await this.fetchData(true);
      },

      async fetchData(silent = false) {
        this.isLoading = !silent;

        if (silent) {
          await this.$refs.player.loadLayout();
          await this.fetchScreenGroupLayout();
          await this.fetchGroups();
        }

        this.isLoading = false;
      },

      async refreshScreen() {
        if (!_.isEmpty(this.$route.query)) {
          this.$router.replace({ query: null });
        }

        this.isRefreshing = true;

        const screenIds = this.screens.map((screen) => screen.id);

        try {
          await this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenGroupPublication,
            data: {
              screen_group_id: this.group.id,
              layout_id: this.layout.layout_id,
            },
          });

          await this.fetchData(true);

          this.$toasted.global.general_success({
            message: `Screen/s refreshed successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to refresh screens' });
        }

        this.isRefreshing = false;
      },

      async fetchScreens() {
        try {
          this.screens = await this.$store.dispatch(GROUP_SCREEN_GROUP_SCREENS, this.groupId);
        } catch (error) {
          console.log('ScreenGroupView fetchScreens error: ', error);
          this.$toasted.global.general_error({ message: 'Screens or Group not found' });
          this.$router.push({ name: 'Screens' });
        }
      },

      async fetchGroups() {
        try {
          await this.$store.dispatch(SCREEN_GROUPS_FETCH);
        } catch (error) {
          console.log('ScreenGroupView fetchGroups error: ', error);
        }
      },

      async fetchGroupPermission() {
        try {
          const response = await apiGetGroupOverridePermission(this.groupId);
          this.isGroupOverridable = response.data.override_permission;
        } catch (error) {
          console.log(error);
          this.isGroupOverridable = true;
        }
      },

      async fetchScreenGroupLayout() {
        try {
          this.screenGroupLayout = await apiGetScreenGroupLayout(this.groupId, this.layoutId);
        } catch (error) {
          console.error('Failed to get screen group layout', error);
        }
      },

      async rotateScreen(orientation) {
        this.isRotating = true;
        try {
          const promises = this.filteredScreens.map(async (screen) => {
            return rotateScreen(screen.id, orientation);
          });

          await Promise.all(promises);
          await this.handleContentChange();

          this.$toasted.global.general_success({
            message: `Screen rotated successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to rotate screen' });
        }
        this.isRotating = false;
      },

      openContentModal() {
        this.$refs.PlaylistItems.openPlaylistContentModal();
      },

      handleScreenDeleted() {
        this.$router.push({ name: 'Screens' });
      },

      goToParentGroup(groupId) {
        this.$store.commit(SET_SELECTED_WIDGET, null);
        this.$router.push({
          name: 'ScreenLayout',
          params: {
            groupId,
            layoutId: this.layout.layout_id,
          },
        });
      },

      goToScreens() {
        if (this.group) {
          this.$store.commit(SET_SCREEN_GROUP, this.group);
        }

        this.$router.push({ name: 'Screens' });
      },

      toggleExpandPlayer() {
        this.expandPlayer = !this.expandPlayer;

        this.$store.commit(SET_SCREEN_SIZE, {
          height: this.playerSize.height,
          width: this.playerSize.width,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-container {
    grid-template-columns: minmax(100px, calc(100% - 376px)) 376px !important;

    .ui-content {
      display: flex;
      height: 100vh;
      flex-direction: column;
      overflow: hidden;
    }

    .breadcrumbs {
      color: $secondaryText;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;

      b {
        color: $primaryText;
      }

      .breadcrumb-group:hover {
        cursor: pointer;
        color: $primaryText;
      }
    }
  }

  .online-status {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;

    .greenLight {
      color: $greenLight;
    }

    .redLight {
      color: $redLight;
    }
  }

  .dropdown-toggle {
    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none !important;
    }
  }

  .sidebar-section {
    padding-top: 16px;
    padding-bottom: 24px;

    & + .sidebar-section {
      border-top: 1px solid $lineGrey;
    }
  }

  .ui-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 40px 20px;

    button.icon {
      border: none;
      background: none;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .left {
      display: flex;
      gap: 20px;

      i {
        color: $primaryText;
      }
    }
  }

  .central-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.expanded {
      align-items: flex-start;
    }
  }

  .ui-content-body {
    min-height: 0;
    flex: 1;
    margin: 40px 40px 36px 40px;
    overflow: auto;

    &.horizontal {
      .display {
        width: 100%;
      }
    }

    &.vertical {
      .display {
        height: 100%;
        max-width: 100%;
      }
    }
    .display {
      display: flex;
      flex-direction: column;

      ::v-deep .web-player-v2 {
        flex: 1;
      }
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
    }

    .layers {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-top: 8px;

      .layer {
        color: $primaryText;
        width: 100%;
        background: #fff;
        border: 1px solid $backgroundGrey3;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .back-button {
    &:hover {
      cursor: pointer;

      i {
        color: $primaryRed;
      }
    }
  }
</style>
